
const URL_CONSTANTS = {
  "banner": "banner",
  "blog": "blogs",
  "contact_us": "contact-us",
  "campaigns": "campaigns",
  "reportList": "reportList",
  "walletAmount": "walletAmount",
  "withdraw_ach": "withdraw_ach",
  "withdrawal": "withdrawal",
  "bank_details": "bank_details",
  "transaction_detail": "campaign_transaction_details",
  "campaign_transactions": "campaign-transactions",
  "category": "categories",
  "causes": "causes",
  "certificates": "certificates",
  "certificates_transactions": "certificates-transactions",
  "charity": "charity_list",
  "charities_transaction_details": "charity_transaction_details",
  "configurations": "configurations",
  "contributions": "contributions",
  "counters": "counters",
  "credit_logs": "credit-logs",
  "dashboard": "dashboard",
  "enquiries": "enquiries",
  "grants": "grants",
  "ledgers": "ledgers",
  "news": "charity_news",
  "pool_transactions": "pool-transactions",
  "pools": "pools",
  "promo_code": "promo-code",
  "referrals": "referrals",
  "support": "support",
  "system_users": "system-users",
  "testimonials": "success_stories",
  "why_truekarma_different": "truekarma_different",
  "withdraw": "withdraw",
  "users": "users",
  "user_send_thank_email": "send_thank_email",
  "volunteer_time": "volunteer-times",
  "volunteer_expense": "volunteer-expense",
  "about_us": "about-us",
  "faq": "faq",
  "help_center": "help_center",
  "why_trueKarma": "why_trueKarma",
  "looking_back": "looking-back",
  "join_us": "join-us",
  "social_media": "social_media",
  "how_it_work": "how_it_work",
  "tracking_activity": "tracking_activity",
  "how_to_donate": "how_to_donate",
  "crowd_funding": "crowd_funding",
  "browse_by_topics": "browse_by_topics",
  "admin_charity_donatoin_list": "charity_transaction_details",
  "admin_campaign_donatoin_list": "admin_campaign_donatoin_list",
  "email_volunteer": "email_volunteer",
  "total_contribution": "total_contribution",
  "events_dashboard": "events_dashboard",
  "events" : "events",
  "bookingEvents": "booking_events",
  "admin_thank_emails":"admin_thank_emails",
  "get_all_report":"get-all-reports",
 "admin_user_check": "admin_user_check",
 "truekarma_fees": "truekarma_fees",
 "contact_list": "contact_list",
 "contact_organizer":"contact_organizer",
 "cms":"cms",
 "user_create_charities": "user_created_charity",
 "admin_approval" : "admin_approval",
 "feed_back":"feed_back",
 "partners":"partners",
 "partners_withdrawals": "partners_withdrawals",
 "get_donation_details": "get_donation_details",
}

const OTHER_CONSTANTS = {
  "login": "login",
  "verify": "verify",
  "sendcode": "sendcode",
  "checkuser": "checkuser",
  "usercounts": "usercounts",
  "fetch_data": "fetch-data",
  "get_token": "get-token",
  "reports": "reports",
  "recreate_stellar": "recreate-stellar",
  "recreate_users": "recreate-users",
  "recreate_logs": "reset-logs"
}

module.exports = {
  URL_CONSTANTS,
  OTHER_CONSTANTS
};